<template>
  <Container
    class="container"
    :preloader="false"
  >
    <template #content>
      <div class="container__control">
        <ClosePopUpButton
          class="black"
        />
      </div>

      <h3>
        {{ $t('components.ui.pages.pop-up.advertising-campaigns.create.title') }}
      </h3>

      <Text
        :placeholder="$t('components.ui.pages.pop-up.advertising-campaigns.create.name')"
        :errors="errors.name"
        :reg-exp="/[^a-z-A-Z-а-я-А-Я-ёЁ-їЇ0-9]/g"
        max="100"
        v-model="form.name"
      />

      <Text
        style="margin-bottom: 10px;"
        :placeholder="$t('components.ui.pages.pop-up.advertising-campaigns.create.slug')"
        :reg-exp="/[^a-z-A-Z0-9]/g"
        max="50"
        :errors="errors.slug"
        v-model="form.slug"
      />

      <div class="container__info">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="8" fill="#DA2828"/>
          <path d="M7.40638 9.33731L7.31496 4H8.70924L8.62924 9.33731H7.40638ZM7.25781 10.3881H8.74353V12H7.25781V10.3881Z" fill="white"/>
        </svg>

        {{ $t('components.ui.pages.pop-up.advertising-campaigns.create.info') }}
      </div>

      <BlueButton
        :text="$t('components.ui.pages.pop-up.advertising-campaigns.create.create')"
        :loading="loader"
        :disabled="loader"
        @click="submit()"
      />

      <div
        class="container__cancel"
        @click="$pop.down()"
      >
        {{ $t('components.ui.pages.pop-up.advertising-campaigns.create.cancel') }}
      </div>
    </template>
  </Container>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import Text from '@/ui/inputs/Text.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    Container,
    ClosePopUpButton,
    Text,
    BlueButton
  },
  data () {
    return {
      loader: false,
      v: useVuelidate(),
      form: {
        name: '',
        slug: ''
      },
      errors: {
        name: [],
        slug: []
      }
    }
  },
  validations () {
    return {
      form: {
        name: { required },
        slug: { required }
      }
    }
  },
  methods: {
    async submit () {
      try {
        this.loader = true
        this.setErrors()

        if (this._countError() === 0) {
          const { status } = await this.$axios.post('private/advertising-campaigns', {
            name: this.form.name, link: this.form.slug
          })

          if (status === 200) {
            this.$router.push({ name: 'AdvertisingСampaigns' })
          }
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loader = false
      }
    },
    setErrors () {
      this.v.form.$touch()
      const errors = this.v.form

      this.errors = {
        name: [],
        slug: []
      }

      if (errors.name.required.$invalid) {
        this.errors.name.push('errors.required')
      }

      if (errors.slug.required.$invalid) {
        this.errors.slug.push('errors.required')
      }
    },
    _countError () {
      var count = 0

      const errors = this.errors
      count += errors.name.length + errors.slug.length
      return count
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  max-width: 430px;
  padding: 40px 20px 20px;
  background: white;
  border-radius: 15px;

  &__control {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  h3 {
    margin-bottom: 20px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
  }

  &__info {
    margin-bottom: 20px;
    padding: 5px 8px 5px 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    background: rgba(218, 40, 40, .1);
    border-radius: 5px;
    font-size: 12px;
    line-height: 100%;
    color: #DA2828;
    font-family: Medium;
  }

  &__cancel {
    margin-top: 25px;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #2F57E9;
    font-family: SemiBold;
    cursor: pointer;
    transition: .2s;

    &:hover {
      color: rgba(87, 127, 255, 1);
    }
  }
}
</style>
